import axios from "axios";
// export const URL = "https://backend.heallocare.net/api/admin/";
export const BASE_URL = "https://backend-dev.heallocare.net/";
export const URL = `${BASE_URL}api/admin/`;
// export const URL = "https://heallocare.com/api/admin/";
// export const URL = "https://medtroops-backend.appssquare.com/api/admin/";
const instance = axios.create({
  baseURL: `${URL}`,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("medtoken")}`,
    "X-Role": "client",
    "X-Language": "en",
  },
});

instance.interceptors.request.use((req) => {
  return req;
});
instance.interceptors.response.use(
  (res) => {
    window.localStorage.setItem("countA", res.headers["joining-requests"]);
    window.localStorage.setItem("countB", res.headers.modification);
    console.log("👉FROM AXIOS INTER COUNTB🟢🟢🟢🟢", res);
    return res;
  },
  (err) => {
    if (
      err &&
      err.response &&
      err.response?.status === 403 &&
      err.response.config.url !== "admin/login"
    ) {
      // window.localStorage.removeItem("medtoken");
      // window.location.replace("/");
    } else if (
      err.response.status === 401 &&
      err.response.config.url !== "admin/login"
    ) {
      window.localStorage.removeItem("medtoken");
      window.location.replace("/");
    }
    // Important: response interceptors **must** return the response.
    // return err;
    else return Promise.reject(err);
  }
);
export default instance;
